import React from 'react';
import {NavLink} from 'react-router-dom';

const SignedOutLinksMobile = () => {
    return(
        <ul className="show-on-small sidenav-close">
            <li><NavLink to='/signup' className='blue-color-accent'>
                <h5 className="nav-link-mobile-first">Sign Up</h5>
            </NavLink></li>
            <li><NavLink to='/signin' className='blue-color-accent'>
                <h5 className="nav-link-mobile">Login</h5>
            </NavLink></li>
        </ul>
    )
}

export default SignedOutLinksMobile;