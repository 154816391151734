import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import DashboardGirls from "./components/dashboard/Girls/DashboardGirls";
import NotificationsMobile from "./components/dashboard/Girls/NotificationsMobile";
import DashboardBoys from "./components/dashboard/Boys/DashboardBoys";
import NotificationsBoys from "./components/dashboard/Boys/NotificationsMobileBoys";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import ProfileDetails from "./components/Profile/ProfileDetails";
import { connect } from "react-redux";
import LandingPage from "./components/layout/LandingPage";
import {isMobile} from 'react-device-detect';
import Media from 'react-media';

class App extends Component {
  render() {
    const { profile } = this.props;

      let dashboard;

        dashboard =
        profile.gender === "Female" ? (
          <Route exact path="/dashboard" component={DashboardGirls} />
        ) : ( 
          <Route exact path="/dashboard" component={DashboardBoys} />
        );

      
    return (
      <BrowserRouter>
        <div className="App">
          <Navbar />
              <Switch>
                {dashboard}
                <Route exact path="/" component={LandingPage} />
                <Route path="/signin" component={SignIn} />
                <Route path="/signup" component={SignUp} />
                <Route path="/profile-details" component={ProfileDetails} />
                <Route path="/notifications-mobile" component={NotificationsMobile} />
            </Switch> 
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(App);
