export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGN_OUT_SUCCESS" });
      });
  };
};

export const signUp = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let gender = newUser.gender;
    if(gender == 'Male' || gender == 'male' || gender == 'MALE'){
      gender = 'Male'
    }else{
      gender = 'Female'
    }

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        return firestore
          .collection("users")
          .doc(resp.user.uid)
          .set({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            gender: gender,
            userID: resp.user.uid,
            aboutMe:'',
            school:'',
            age: '',
            imgFileURL:
              "https://firebasestorage.googleapis.com/v0/b/sampetrinos.appspot.com/o/images%2F1401985c-119f-4279-bd2d-e95867bf739b.png?alt=media&token=b12f3da6-f18a-4841-abae-981a90301082",
            initials: newUser.firstName[0] + newUser.lastName[0],
            boysNotToDisplay: []
          });
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};
