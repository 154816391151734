import React from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {signOut} from '../../store/actions/authActions';

const SignedInLinksMobile = (props) => {
    let notifications = props.profile.gender == 'Female' ? (
        <li><NavLink to='/notifications-mobile' className='blue-color-accent'>
            <h5 className=" nav-link-mobile">Notifications</h5>
        </NavLink></li>
    ) : (
        null
    )
    return(
        <div>
            <ul className="show-on-small sidenav-close">
                <li><NavLink to='/dashboard' className='blue-color-accent'>
                    <h5 className="nav-link-mobile-first">Home</h5>
                </NavLink></li>
                {notifications}
                <li><NavLink to='/profile-details' className='blue-color-accent'>
                    <h5 className=" nav-link-mobile">Profile</h5>
                </NavLink></li>
                <li><a onClick={props.signOut} className='blue-color-accent'>
                    <h5 className="nav-link-mobile">Log Out</h5>
                </a></li>
            </ul>
        </div>

    )
}

const mapDispatchToProps = (dispatch) => {
    return{
        signOut: () => dispatch(signOut())
    }
   
}


export default connect(null, mapDispatchToProps)(SignedInLinksMobile);