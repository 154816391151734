import React, { Component } from "react";
import Notifications from "./Notifications";
import Boy from "./Boy";
import { connect } from "react-redux";
import { likeBoy } from "../../../store/actions/boyActions";
import { addBoyToBoysNotToDisplay } from "../../../store/actions/boyActions";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import Chat from "../chat/chat";
import { left } from "styled-system";
import firebase from "firebase";
import { updateLastSeenGirl } from "../../../store/actions/chatActions";

class DashboardGirls extends Component {
  state = {
    idBoy: "",
    idGirl: "",
    firstName: "Rodrigo Miñaro",
    imgFileURL: "https://firebasestorage.googleapis.com/v0/b/sampetrinos.appspot.com/o/images%2F1401985c-119f-4279-bd2d-e95867bf739b.png?alt=media&token=b12f3da6-f18a-4841-abae-981a90301082",
    actualChat: null,
    school: "",
    aboutMe: "",
  };

  setActualChat = async (idx, userID) => {
    const { matches } = this.props;
    if (idx === -1) {
      this.setState({ actualChat: null });
    } else {
      await this.setState({ actualChat: null });
      this.setState({ actualChat: matches[idx] });
      this.props.updateLastSeenGirl(matches[idx].id);
    }
  };

  //Boys not to display
    //1. Agregar un arreglo vacio al sign up de una chava
    //2. Hacer una función que llama un dispatch que se va a llamar cuando una chava likea o no likea a un chavo
    //3. Al dispatch le voy a mandar el id del chavo
    //4. En la action voy hacer un get() y voy a guardar el arreglo que ya tengo en un arreglo local, 
    //voy a pushiarle el id del chavo al arreglo local,
    //Y después voy a updatiar el documento con el arreglo local
    
    //Agarrar un chavo que sí quiero ver
    //6. Crear una función que va a recibir a todos los boys y también el arreglo de boysNotToDisplay de este usuario
    //7. Voy hacer un for-loop que va checar si el chavo a desplegar esta en boysNotToDisplay
    //Si sí está, sigue el forlopp, si no está return boy

  boysNotToDisplay = (boyId, girlId) => {
    this.props.addBoyToBoysNotToDisplay(boyId, girlId)
  };

  boysToDisplay = (boys, boysNotToDisplay) => {
    //Voy hacer un for-loop que va checar si el chavo a desplegar esta en boysNotToDisplay
    for(let i = 0; i < boys.length; i++){
      if(boysNotToDisplay.includes(boys[i].id) == false)
        return boys[i]
    }
  };

  StopShowingBoy = () => {
    this.setState({
      idBoy: "",
      idGirl: "",
      firstName: "",
      lastName: "",
      imgFileURL: "",
      actualChat: null,
      school: "",
      age: "",
      aboutMe: ''
    });
  };


  handleClickToChangeBoy = (e) => {
    const { boys, auth, profile} = this.props;
    //Cuando hace un like o X, update el arreglo de boys not to display
    //let boy = this.boyToDisplay();
    let boy = this.boysToDisplay(boys, profile.boysNotToDisplay);
    if(boy !== undefined){
      this.setState({
        idBoy: boy.id,
        idGirl: auth.uid,
        firstName: boy.firstName,
        lastName: boy.lastName,
        imgFileURL: boy.imgFileURL,
        school: boy.school,
        age: boy.age,
        aboutMe: boy.aboutMe
      });
      this.boysNotToDisplay(boy.id, auth.uid)
    }else{
      this.StopShowingBoy()
    }
  };

  handleClickToLikeBoy = (e) => {
    this.props.likeBoy(this.state);
    const { boys, auth, profile} = this.props;
    //Cuando hace un like o X, update el arreglo de boys not to display
    //let boy = this.boyToDisplay();
    let boy = this.boysToDisplay(boys, profile.boysNotToDisplay);
    if(boy !== undefined){
      this.setState({
        idBoy: boy.id,
        idGirl: auth.uid,
        firstName: boy.firstName,
        lastName: boy.lastName,
        imgFileURL: boy.imgFileURL,
        school: boy.school,
        age: boy.age,
        aboutMe: boy.aboutMe
      });
      this.boysNotToDisplay(boy.id, auth.uid)
    }else{
      this.StopShowingBoy()
    }
  };

  render() {
    const { auth, users, matches, profile } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;
    const { actualChat } = this.state;
    let action;
    if (actualChat !== null) {
      action = (
        <div
          style={{
            padding: "0%",
            width: "10",
            position: "absolute",
            marginLeft: "35%",
            backgroundColor: "white",
            borderTop: "3px solid lightgrey",
          }}
        >
          <Chat
            closeChat={() => this.setActualChat(-1)}
            chat={actualChat.id}
            user={users[actualChat.idUserBoy]}
          />
        </div>
      );
    } else if(this.state.firstName !== ''){
      action = (
        <div className="center">
          <Boy
            boy={this.state}
            handleClickToLikeBoy={this.handleClickToLikeBoy}
            handleClickToChangeBoy={this.handleClickToChangeBoy}
          />
        </div>
      );
    }else{
      action = (
        <div class="col s12 m7 l6">
          <div className="section center intro-text">
            <h5 className="black-text intro-text">
              You are all catched up!
            </h5>
            <i class="medium material-icons blue-color-accent">mood</i>
          </div>
        </div>
      );
    }

    
    return (
      <div className="dashboard container">
        <div className="row">
          <div className="col s12 m5 l6 hide-on-small-only">
              <Notifications
                openChat={this.setActualChat}
                matches={matches}
                users={users}
                profile={profile}
              />
          </div>
            {action}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const users = state.firestore.ordered.users || state.boy.boys;
  const gender = "Male";
  const boys = users.filter((boy) => {
    if (boy.gender === gender) return boy;
  });
  return {
    auth: state.firebase.auth,
    matches: state.firestore.ordered.matches,
    profile: state.firebase.profile,
    users: state.firestore.data.users,
    boys: boys,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    likeBoy: (boy) => dispatch(likeBoy(boy)),
    updateLastSeenGirl: (convID) => dispatch(updateLastSeenGirl(convID)),
    addBoyToBoysNotToDisplay: (boyId, girlId) => dispatch(addBoyToBoysNotToDisplay(boyId, girlId))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (props.profile.userID === undefined) return [];

    return [
      {
        collection: "matches",
        where: [["idUserGirl", "==", props.profile.userID],["status", "==", true]],
        
      },
      { collection: "users" },
    ];
  })
)(DashboardGirls);
