import React from "react";

const BoySummary = ({ boy, handleClickToChangeBoy, handleClickToLikeBoy }) => {
  let age;
  let comma;
  if(boy.age == ''){
    age = null;
  }else{
    age = boy.age
  }
    
  

  return (
    <div className="card center">
      <div className="card-image center">
        <img className="responsive-img" src={boy.imgFileURL} />
      </div>
      <div className="card-content">
        <h5 className="boy-name">{boy.firstName}, {age} </h5>
        <div className="divider"></div>
        <p className="bold boy-name grey-text text-darken-2">{boy.school}</p>
        <p className="bold about-me grey-text text-darken-2">{boy.aboutMe}</p>
        <button
          className="waves-effect btn-floating waves-light btn-large white"
          onClick={() => handleClickToChangeBoy()}
        >
          <i class="large material-icons grey-text">close</i>
        </button>
        <button
          className="waves-effect btn-floating waves-light btn-large white"
          onClick={() => handleClickToLikeBoy()}
        >
          <i class="large material-icons blue-text text-darken-4">check</i>
        </button>
      </div>
    </div>
  );
};

export default BoySummary;
